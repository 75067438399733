<template>
  <!-- We need this double div with the toggle so video js can properly change the video if the source changes. This is for example the case when we have 2 videos in the PDP stage element and switch between them. -->
  <div v-if="toggle" ref="divElement" class="h-full">
    <video ref="videoElement" class="video-js"></video>
  </div>
  <div v-else ref="divElement" class="h-full">
    <video ref="videoElement" class="video-js"></video>
  </div>
</template>

<script setup lang="ts">
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import type Player from 'video.js/dist/types/player'
import { falsy } from '~/helpers/type'

// Could not find a proper type for this in video.js >= 8 and @types/video.js is only for <= 7 https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/65641#discussioncomment-6116045
interface VideoJsPlayerOptions {
  autoplay?: boolean
  bigPlayButton?: boolean
  controls?: boolean
  fill?: boolean
  loop?: boolean
  poster?: string
  sources: {
    src: string
    type?: string
  }[]
  muted?: boolean
  controlBar?: {
    volumePanel?: boolean
  }
  playsinline?: boolean
}

export type MediaVideoProps = {
  videoClass?: string
} & VideoJsPlayerOptions

const props = withDefaults(defineProps<MediaVideoProps>(), {
  // playsinline is needed that the video does not pop to fullscreen automatically on mobile phones. Tested on iPhone.
  playsinline: true,
  bigPlayButton: false,
  autoplay: false,
  preload: 'metadata',
})

const videoElement = ref<HTMLVideoElement>()
// We need to observe the div with the intersectionObserver since video.js replaces the video element when initialized. This breaks the observer on iOS Safari.
const divElement = ref<HTMLDivElement>()
const player = ref<Player>()
const toggle = ref(false)

watch(props, () => {
  // player.value.options(newProps) does not work. We need to set the options manually.
  player.value?.controls(props.controls)

  if (player.value?.poster() !== props.poster) {
    player.value?.loadMedia(
      {
        poster: props.poster,
      },
      () => {},
    )
  }

  player.value?.muted(props.muted)
})

const intersectionObserver = ref<IntersectionObserver>()

const initPlayer = () => {
  if (!videoElement.value) {
    return
  }

  const playerOptions: VideoJsPlayerOptions = {
    ...props,
    // set autoplay always to false. We do not want to play the video if it is not in the viewport. With the intersectionObserver with programmatically press play and pause if autoplay is set to true.
    autoplay: false,
  }
  player.value = videojs(
    videoElement.value,
    playerOptions,
    function (this: Player) {
      // Add the videoClass to the video element
      const classes = props.videoClass?.split(' ').filter(falsy) ?? []
      if (classes.length > 0) {
        this.player()
          // See https://github.com/videojs/video.js/issues/2617
          .tech({ IWillNotUseThisInPlugins: true })
          .el()
          .classList.add(...classes)
      }
    },
  )
}

const resetPlayer = () => {
  intersectionObserver.value?.disconnect()
  // do not dispose the player here, although it would probably make sense. However we initiallize it so fast again that video.js still tries to do some clean up on the old player which leads to the following warning in the console log:
  // VIDEOJS: WARN: removeClass was called with an element that doesn't exist
  // player.value?.dispose()
  player.value = undefined

  toggle.value = !toggle.value

  // we need next tick here so that the divElement ref points to the correct element after toggle change.
  nextTick(() => {
    if (!divElement.value) {
      return
    }

    // autoplay only when the video is inside the viewport.
    intersectionObserver.value = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        let justInitialized = false
        if (!player.value && entry.isIntersecting) {
          // only init the player on first intersection. This way we avoid loading the first .ts file for all videos on the page.
          initPlayer()
          justInitialized = true
        }

        if (player.value && props.autoplay) {
          if (entry.isIntersecting) {
            player.value.play()
            if (justInitialized) {
              // when switching fast between two videos in the PDP stage element on desktop. Sometimes the video does not start playing. This is a fix for that. Using nextTick() is not enough. We need the 500ms delay.
              setTimeout(() => {
                player.value?.paused()
                if (player.value?.paused()) {
                  player.value.play()
                }
              }, 500)
            }
          } else if (player.value.muted()) {
            player.value.pause()
          }
        }
      })
    })

    intersectionObserver.value.observe(divElement.value)
  })
}

watch(
  () => props.sources,
  () => {
    if (import.meta.client) {
      // Only reset the player, when props.sources changes on the client.
      // Otherwise nuxt crashes in SSR context.
      // We reset the player also in onMounted() anyways.
      resetPlayer()
    }
  },
)

const play = () => {
  player.value?.play()
}

defineExpose({
  play,
})

onMounted(() => {
  resetPlayer()
})

onUnmounted(() => {
  intersectionObserver.value?.disconnect()
  player.value?.dispose()
})
</script>

<style scoped lang="scss">
.video-js {
  @apply bg-grey-light-01;
  width: 100%;
  height: 100%;
}

:deep(.vjs-poster img) {
  object-fit: cover;
}
</style>
