<template>
  <div class="paragraph relative">
    <ParagraphBaseImageVideoBase
      :overline="overline"
      :headline="headline"
      :link="actualLink"
      :link-action="linkAction"
      :is-editing="isEditing"
      :overlay="overlay"
      :hide-overlay="onlyShowTheMedia"
    >
      <template #image>
        <MediaVideo
          v-if="video"
          ref="mediaVideo"
          :video="video.video"
          :autoplay="autoplayAndLoop && !isEditing"
          :loop="autoplayAndLoop"
          :big-play-button="false"
          :poster="video.poster"
          :video-src="videoSrc"
          :show-mute-button="!noSoundButton"
        />
        <div v-if="autoplayAndLoop && isEditing" class="bg-grey-mid">
          Autoplay is disabled while editing
        </div>
      </template>

      <template v-if="actualLink || !hidePlayButton" #callToActionText>
        <span>
          {{
            actualLink
              ? $texts('readMore', 'Read more')
              : $texts('playVideo', 'Play Video')
          }}
        </span>

        <img
          v-if="!actualLink"
          src="@/assets/symbols-from-figma/figma-play.svg"
          class="h-[1em] w-[1em] self-center"
        />
      </template>
    </ParagraphBaseImageVideoBase>
  </div>
</template>

<script lang="ts" setup>
import type { MediaVideo } from '#build/components'
import type { ParagraphVideoFragment } from '#build/graphql-operations'

const { $texts } = useEasyTexts()

const { isEditing } = defineBlokkli({
  bundle: 'video',
})

export type ParagraphVideoProps = ParagraphVideoFragment & {
  link?: ParagraphVideoFragment['link'] | string
  videoSrc?: string
}

const props = defineProps<ParagraphVideoProps>()

const actualLink = computed(() => {
  if (props.autoplayAndLoop) {
    if (typeof props.link === 'string') {
      return props.link
    } else {
      return props.link?.uri?.path
    }
  } else {
    return undefined
  }
})

const onlyShowTheMedia = ref(false)
const mediaVideo = ref<typeof MediaVideo>()

const linkAction = computed(() => () => {
  if (!actualLink.value) {
    if (mediaVideo.value) {
      mediaVideo.value.play()
      onlyShowTheMedia.value = true
    }
  }
})
</script>
