<template>
  <div class="h-full">
    <MediaVideoPlayer
      v-if="actualSrc"
      ref="mediaVideoPlayer"
      :poster="posterUrl"
      :sources="[
        {
          src: videoSrc ?? actualSrc,
          type: 'application/x-mpegURL',
        },
      ]"
      :autoplay="autoplay"
      :loop="loop"
      class="w-full"
      :controls="nativeVideoPlayerShowing"
      :fill="true"
      :big-play-button="bigPlayButton"
      :muted="muted"
      :video-class="videoClass"
    />
    <div
      class="paragraph-video-volume-icon absolute z-[100] cursor-pointer rounded-full"
      :class="{
        muted: muted,
        hidden: nativeVideoPlayerShowing || !showMuteButton || !autoplay,
      }"
      @click="muted = !muted"
    >
      <SpriteSymbol
        v-if="muted"
        class="h-full w-full"
        name="volume-muted-line-icon"
      />
      <SpriteSymbol
        v-else
        class="h-full w-full"
        name="volume-medium-line-icon"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaVideoPlayer } from '#build/components'
import type { MediaVideoFragment } from '#build/graphql-operations'
import 'video.js/dist/video-js.css'
import { getBasicRokkaUrl } from '~/helpers/rokka'

type MediaVideoProps = {
  videoClass?: string
  video: MediaVideoFragment['video']
  poster: MediaVideoFragment['poster']
  autoplay?: boolean
  loop?: boolean
  bigPlayButton?: boolean
  videoSrc?: string
  showMuteButton?: boolean
}

const props = defineProps<MediaVideoProps>()

const videoRokkaHash = computed(() => props.video?.file?.rokka?.hash)
const posterUrl = computed<string | undefined>(() => {
  return getBasicRokkaUrl(
    props.poster?.image?.file?.rokka?.hash ?? videoRokkaHash.value,
  )
})

const actualSrc = computed(() => {
  return getBasicRokkaUrl(
    videoRokkaHash.value ?? props.video?.file?.rokka?.hash,
    'm3u',
  )
})

const muted = ref(true)

// This is probably better suited in videoPlayer.vue, but I don't know how to best query for this and then propagate it back to this component so we sort of fake the calculations if the native video player is showing our selves.
const nativeVideoPlayerShowing = ref(false)

const mediaVideoPlayer = ref<typeof MediaVideoPlayer>()
const play = () => {
  if (mediaVideoPlayer.value) {
    mediaVideoPlayer.value.play()
    muted.value = false
    nativeVideoPlayerShowing.value = true
  }
}

const mute = () => {
  muted.value = true
}

defineExpose({
  play,
  mute,
})
</script>

<style lang="scss" scoped>
.paragraph-video-volume-icon {
  $padding: min(max(1cqw, 10px), 17px);
  padding: calc($padding * 1.2);
  padding-left: $padding;
  bottom: max(3cqw, 24px);
  right: max(3cqw, 24px);
  $size: min(max(5cqw, 48px), 70px);
  width: $size;
  height: $size;
  background: #efefef;
  opacity: 0.6;

  &.muted {
    padding: $padding;
  }
}
</style>
